import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import "react-quill/dist/quill.snow.css";
import "./style.css";
import { GetIcon } from "../../../icons";
import { Editor } from "./styles";
import CustomLabel from "../input/label";
import { appTheme } from "../../project/brand/project";
import Footnote from "../input/footnote";
const modules = {
  toolbar: [
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    [{ align: [] }], // add justify format
    ["link", "image"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const formats = ["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "video", "color", "background", "script", "align", "direction", "code-block", "formula", "clean"];

const EditorNew = (props) => {
  const [showEditor, setShowEditor] = useState(false);

  const openFullScreen = () => {
    setShowEditor(!showEditor);
  };
  return (
    <Overlay className={(props.customClass ?? "full") + " " + props.dynamicClass + " " + (showEditor && "open") + (props.value?.length > 0 ? " has" : "")}>
      <Page className={showEditor && "open"}>
        {showEditor && (
          <Button onClick={() => openFullScreen()}>
            <>
              <span>Close Full screen</span>
              <GetIcon icon={"Close"}></GetIcon>
            </>
          </Button>
        )}
        <CustomLabel required={props.required} label={props.label ?? props.placeholder} error={props.error}></CustomLabel>
        <Editor
          theme="snow"
          value={props.value}
          formats={formats}
          modules={modules}
          onChange={(event) => {
            props.onChange(event, props.id, props.type);
          }}
        />
        <Footnote {...props} />
        {!showEditor && (
          <Button onClick={() => openFullScreen()}>
            <>
              <span>Open Full Screen</span>
              <GetIcon icon={"enlarge"}></GetIcon>
            </>
          </Button>
        )}
      </Page>
    </Overlay>
  );
};

export default EditorNew;

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  &.small {
    grid-column: span 1; /* 25% width */
  }

  &.half {
    grid-column: span 2; /* 50% width */
  }

  &.large {
    grid-column: span 3; /* 75% width */
  }
  &.full {
    grid-column: span 4; /* 100% width */
  }
  &.disabled {
    display: none;
  }
  p {
    text-align: initial;
    color: ${appTheme.text.soft};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
  }
  &.has p {
    color: ${appTheme.text.main};
  }
  &.open {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 25px 0;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2000;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    top: 0;
    bottom: 0;
    border-top: 1px solid rgb(224, 224, 227);
  }
`;
const Button = styled.div`
  background-color: transparent;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-items: center;
  align-self: end;
  cursor: pointer;
  z-index: 1;
  font-size: 14px;
  svg,
  i {
    margin-left: 10px;
  }
`;
const slideAnimation = keyframes`  
  from {  
    transform: translateX(100%); 
    opacity: 0;
  }  
  to {  
    transform: translateX(0); 
    opacity: 1;
  }  
`;
const Page = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  background-color: white;
  border-radius: 10px;
  /* max-height: 300px; */
  width: 100%;
  &.open {
    display: flex;
    flex-direction: column;
    display: flex;
    background-color: white;
    border-radius: 0px;
    width: 50%;
    min-width: 250px;
    animation: ${slideAnimation} 1s ease-in-out;
    animation-duration: 0.2s;
    padding: 1em;
    left: auto;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    position: relative;
    overflow: auto;
    right: 0;
    left: auto;
    margin-left: auto;
  }
  @media (max-width: 768px) {
    width: 100%;
    position: relative;
    top: 0;
    padding: 0;
    &.open {
      width: 90%;
    }
  }
`;
