import React from "react";
import styled from "styled-components";
import { appTheme } from "../../project/brand/project";
import CustomLabel from "../input/label";

const ToggleContainer = styled.label`
  background-color: ${appTheme.bg.soft}; /* Background color for OFF state */
  display: flex;
  width: 28px; /* Width of the toggle switch */
  height: 16px; /* Height of the toggle switch */
  position: relative;
  cursor: pointer;
  border-radius: 34px; /* Rounded edges */
  padding: 2px;
  transition: background-color 0.4s; /* Smooth transition for background color */
  margin-right: 8px;
  &.true {
    background-color: ${appTheme.primary.base}; /* Background color for ON state */
  }
`;
const Container = styled.div`
  display: flex;
`;
const ToggleSlider = styled.span`
  cursor: pointer;
  width: 12px; /* Width of the slider/knob */
  height: 12px; /* Height of the slider/knob */
  border-radius: 56px; /* Circular shape */
  background: white; /* Color of the knob */
  display: flex;
  position: relative;
  transition: left 0.4s; /* Smooth transition for knob movement */

  box-shadow: 0px 2px 4px 0px #1b1c1d05; /* Shadow for depth */

  &.true {
    left: 12px; /* Position of the slider when ON */
  }

  &:before {
    content: "";
    position: absolute;
    background: rgb(226, 228, 233);
    width: 4px; /* Size of the inner dot */
    height: 4px;
    top: 4px; /* Positioning of the inner dot */
    left: 4px;
    border-radius: 50%; /* Circular shape for inner dot */
    transition: background 0.4s; /* Smooth transition for inner dot color */
  }

  &.true:before {
    background: ${appTheme.primary.base}; /* Background color for inner dot when ON */
  }
`;

const OnOffToggle = ({ on, handleToggle, label, description }) => {
  return (
    <Container className={on ? "true" : ""}>
      <ToggleContainer onClick={() => handleToggle(!on)} className={on ? "true" : ""}>
        <ToggleSlider className={on ? "true" : ""} />
      </ToggleContainer>
      <CustomLabel className="toggle" description={description} label={label}></CustomLabel>
    </Container>
  );
};

export default OnOffToggle;
